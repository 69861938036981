import { Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'default-modal',
    templateUrl: 'default-modal.component.html',
    styleUrls: ['default-modal.component.css']
})

export class DefaultModalComponent {
    @Input() title = `Information`;

    constructor(
      public activeModal: NgbActiveModal
    ) {}
  
    ngOnInit() {
    }
}